
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { watch } from '@vue/runtime-core';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { RefreshIcon, XCircleIcon } from '@heroicons/vue/solid';
import {
  Trip,
  Session,
  Passenger,
  OrganizationCoreVersionMapping,
} from '@/models';
import { getOrganizationCoreVersionMapping } from '@/api/organizations.api';
import { getTrips } from '@/api/trips.api';
import { getSessions } from '@/api/sessions.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import {
  titlize,
  removeUnderscores,
  capitalizeFirstLetter,
  interpretOptionalString,
} from '@/components/utils';
import store from '@/store';
import DatePickerGroup from '@/components/DatePickerGroup.vue';
import SelectorList from '@/components/SelectorList.vue';
import airlinesList from '@/constants/availableAirlines';
import moment from 'moment';
import { isSameCalendarDate } from '@/components/utils/date.utils';

export default defineComponent({
  name: 'trips',
  components: {
    ChevronRightIcon,
    RefreshIcon,
    XCircleIcon,
    SystemStatusBadge,
    DatePickerGroup,
    SelectorList,
  },
  setup() {
    const route = useRoute();
    const searchQuery = ref(route.params.id as string);
    const filterQuery = ref('');
    const executed = ref(false);
    const loading = ref(false);
    const error = ref('');
    const page = ref(1);
    const pageSize = 10;
    const combinedPageSize = ref(pageSize);
    const startDate = ref(moment().subtract(14, 'days').toDate());
    const endDate = ref(new Date());

    const isTripFilterAltered = computed(() => {
      // Check If default state has changed for any of the fields. If one is true we show the filter.
      const filterQueryChanged = filterQuery.value !== '';
      const selectedAirlinesChanged = selectedAirlines.value.length !== 0;
      const searchQueryChanged = searchQuery.value !== route.params.id;
      const startDateChanged = !isSameCalendarDate(
        startDate.value as Date,
        new Date()
      );
      const endDateChanged = !isSameCalendarDate(
        endDate.value as Date,
        new Date()
      );
      return (
        filterQueryChanged ||
        selectedAirlinesChanged ||
        searchQueryChanged ||
        startDateChanged ||
        endDateChanged
      );
    });

    const sharedState = store.state;
    const isV1 = ref(false);
    const isV2 = ref(false);

    const selectedAirlines = ref([] as string[]);
    const airlineOptions = ref([] as { name: string; id: string }[]);

    const records = ref([] as (Session | Trip)[]);
    const recordsFiltered = ref([] as (Session | Trip)[]);

    const sessionCancelController = ref(new AbortController());
    const tripCancelController = ref(new AbortController());

    const getTripsOrSessions = async () => {
      loading.value = true;
      // Fetch the Org Mapping only on first call. We need to change this so that we look into the integration objects for this since we already have them loaded.
      if (!isV1.value && !isV2.value) {
        const organizationId = sharedState.integration?.organization?.id;
        const response = await getOrganizationCoreVersionMapping(
          organizationId
        );
        const organizationCoreMapping =
          response.data as OrganizationCoreVersionMapping;
        isV1.value = organizationCoreMapping.isV1;
        isV2.value = organizationCoreMapping.isV2;
      }

      if (isV1.value && isV2.value) {
        records.value = await fetchTripsAndSessions();
        combinedPageSize.value = 20;
      } else if (isV1.value) records.value = await fetchSessions();
      else if (isV2.value) records.value = await fetchTrips();

      executed.value = true;
      loading.value = false;
    };

    const fetchTrips = async () => {
      error.value = '';
      try {
        const response = await getTrips(
          page.value,
          pageSize,
          'desc',
          searchQuery.value,
          startDate.value as Date,
          endDate.value as Date,
          selectedAirlines.value,
          tripCancelController.value
        );
        return response.data.trips as Trip[];
      } catch (e) {
        error.value = 'Error fetching trips';
      }
      return [] as Trip[];
    };

    const fetchSessions = async () => {
      error.value = '';
      try {
        const response = await getSessions(
          page.value,
          pageSize,
          'desc',
          searchQuery.value,
          startDate.value as Date,
          endDate.value as Date,
          selectedAirlines.value,
          sessionCancelController.value
        );
        return response.data.sessions as Session[];
      } catch (e) {
        error.value = 'Error fetching sessions';
      }
      return [] as Session[];
    };

    const fetchTripsAndSessions = async () => {
      const sessionsData = await fetchSessions();
      const tripsData = await fetchTrips();
      return [...sessionsData, ...tripsData];
    };

    const getTripProductInfo = function (trip: any) {
      const products: { [key: string]: number } = {
        seat: 0,
        bag: 0,
        seating_choice: 0,
      };
      for (const order in trip.orders) {
        products[trip.orders[order].productType as string] += 1;
      }

      const productInfo = [];
      for (const [key, value] of Object.entries(products)) {
        if (value > 0) {
          productInfo.push(titlize(`${value} ${key}${value != 1 ? 's' : ''}`));
        }
      }
      if (productInfo.length) return productInfo.join(', ');
      return 'No products';
    };

    const getSessionProductInfo = function (session: any) {
      const products: { [key: string]: number } = {
        seat: 0,
        bag: 0,
        seating_choice: 0,
      };
      session.products
        ?.filter((p: any) => ['offered', 'accepted'].indexOf(p.status) === -1)
        .forEach((product: any) => {
          products[product.productType as string] += 1;
        });
      const productInfo = [];
      for (const [key, value] of Object.entries(products)) {
        if (value > 0) {
          productInfo.push(titlize(`${value} ${key}${value != 1 ? 's' : ''}`));
        }
      }
      if (productInfo.length) return productInfo.join(', ');
      return 'No products';
    };

    const getTripCarrierInfo = function (trip: any) {
      const carriers: string[] = [];
      trip.journeys?.forEach(
        (journey: { segments: { marketingAirline: string }[] }) => {
          journey.segments.forEach((segment: { marketingAirline: string }) => {
            if (carriers.indexOf(segment.marketingAirline) === -1)
              carriers.push(segment.marketingAirline);
          });
        }
      );
      if (carriers.length) return carriers.join(', ');
      return 'N/A';
    };

    const getTripPNRs = function (trip: any) {
      const pnrs: string[] = [];
      trip.tickets?.forEach(
        (ticket: { accessDetails: { recordLocator: string } }) => {
          if (pnrs.indexOf(ticket?.accessDetails?.recordLocator) === -1)
            pnrs.push(ticket?.accessDetails?.recordLocator);
        }
      );
      if (pnrs.length) return pnrs.join(', ');
      return 'N/A'; // should never be the case there are no pnrs
    };

    const getSessionPNRs = function (session: any) {
      let res = 'N/A';
      if (session.pnrs?.length) res = session.pnrs.join(', ');
      return res; // should never be the case there are no pnrs
    };

    const getPassengers = function (record: any) {
      const passengers: Passenger[] = [];
      record.passengers?.forEach((passenger: Record<string, unknown>) => {
        const passengerData: { [key: string]: string } = {};
        for (const [key, value] of Object.entries(passenger)) {
          passengerData[capitalizeFirstLetter(removeUnderscores(key))] =
            value as string;
        }
        passengers.push(passengerData as unknown as Passenger);
      });
      return passengers;
    };

    const getCarrierInfo = function (session: any) {
      const carriers: string[] = [];
      session.products
        ?.filter((p: any) => ['offered', 'accepted'].indexOf(p.status) === -1)
        .forEach((product: any) => {
          if (carriers.indexOf(product.carrier) === -1) {
            carriers.push(product.carrier);
          }
        });
      if (carriers.length) return carriers.join(', ');
      return 'N/A';
    };

    const changePage = function (direction: 'next' | 'prev') {
      if (direction == 'next') {
        page.value += 1;
      } else if (direction == 'prev') {
        page.value -= 1;
      }
      records.value = [];
      getTripsOrSessions();
    };

    const abortRequests = () => {
      sessionCancelController.value.abort();
      tripCancelController.value.abort();

      sessionCancelController.value = new AbortController();
      tripCancelController.value = new AbortController();
    };

    const resetFilters = () => {
      filterQuery.value = '';
      selectedAirlines.value = [];
      searchQuery.value = route.params.id as string;
      startDate.value = new Date();
      endDate.value = new Date();
    };

    watch(
      () => filterQuery.value || records.value,
      () => {
        const filter = filterQuery.value.toLowerCase().trim();
        recordsFiltered.value =
          !filter || !records.value.length
            ? records.value
            : records.value?.filter((record: Session | Trip) => {
                const session = record as Session;
                const trip = record as Trip;
                return (
                  getTripPNRs(trip).toLowerCase().includes(filter) ||
                  getSessionPNRs(session).toLowerCase().includes(filter) ||
                  getTripProductInfo(trip).toLowerCase().includes(filter) ||
                  getSessionProductInfo(session)
                    .toLowerCase()
                    .includes(filter) ||
                  getTripCarrierInfo(trip).toLowerCase().includes(filter) ||
                  getCarrierInfo(session).toLowerCase().includes(filter) ||
                  interpretOptionalString(trip.service)
                    .toLowerCase()
                    .includes(filter) ||
                  removeUnderscores(record.status as string)
                    .toLowerCase()
                    .includes(filter) ||
                  getPassengers(record).filter(
                    (passenger: Passenger) =>
                      Object.values(passenger).filter(
                        (val: string) =>
                          typeof val === 'string' &&
                          val.toLowerCase().includes(filter)
                      ).length
                  ).length
                );
              });
      }
    );

    watch(
      () => selectedAirlines.value,
      () => {
        abortRequests();
        getTripsOrSessions();
      }
    );

    watch(
      () => route.params.id,
      (oldValue, newValue) => {
        if (oldValue !== newValue) {
          searchQuery.value = route.params.id as string;
          getTripsOrSessions();
        }
      }
    );

    watch(
      () => sharedState.integration?.organization?.name,
      () => {
        if (sharedState.integration?.organization?.name) {
          getTripsOrSessions();
        }
      },
      { immediate: true }
    );

    const onDateChange = (data: { startDate: Date; endDate: Date }) => {
      startDate.value = data.startDate;
      endDate.value = data.endDate;

      abortRequests();
      getTripsOrSessions();
    };

    const setAirlinesOptions = () => {
      const options: { name: string; id: string }[] = airlinesList.map(
        (airline) => ({ name: airline, id: airline })
      );
      airlineOptions.value = options;
    };

    onMounted(() => {
      setAirlinesOptions();
    });

    return {
      records,
      recordsFiltered,
      executed,
      loading,
      error,
      page,
      pageSize,
      combinedPageSize,
      searchQuery,
      filterQuery,
      removeUnderscores,
      titlize,
      capitalizeFirstLetter,
      interpretOptionalString,
      getTripsOrSessions,
      getSessionProductInfo,
      getTripProductInfo,
      getCarrierInfo,
      getTripCarrierInfo,
      getTripPNRs,
      getSessionPNRs,
      changePage,
      isV1,
      isV2,
      startDate,
      endDate,
      onDateChange,
      selectedAirlines,
      airlineOptions,
      isTripFilterAltered,
      resetFilters,
    };
  },
});
