<template>
  <div class="w-72 relative">
    <div class="relative mt-1">
      <button
        class="w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 text-sm h-11"
        @click="updateIsListOpen"
      >
        <span class="block truncate">{{ renderHeader() }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </button>
    </div>
    <div
      v-if="isListOpen"
      class="w-72 bg-gray border-indigo-500 absolute overflow-scroll max-h-36 z-20"
    >
      <div
        class="w-full mt-1 flex flex-col items-start justify-center p-2 rounded-lg bg-white shadow-md z-"
      >
        <Checkbox
          v-for="option in options"
          :checked="value.includes(option.id)"
          @update:checked="check(option.id, $event)"
          :fieldId="option.name"
          :label="option.name"
          :key="option"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { SelectorIcon } from '@heroicons/vue/solid';
import Checkbox from './Checkbox.vue';

export default {
  emits: ['update:value'],
  props: {
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
          Object.keys(option).includes('name')
        );
        const hasIdKey = value.every((option) =>
          Object.keys(option).includes('id')
        );
        return hasNameKey && hasIdKey;
      },
    },
  },
  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit('update:value', updatedValue);
    };

    const isListOpen = ref(false);
    const updateIsListOpen = () => {
      isListOpen.value = !isListOpen.value;
    };

    const renderHeader = () => {
      let val = [...props.value];
      if (val.length > 0) {
        return [...props.value].join(',');
      }
      return 'All Airlines';
    };

    return {
      check,
      isListOpen,
      updateIsListOpen,
      renderHeader,
    };
  },
  components: {
    Checkbox,
    SelectorIcon,
  },
};
</script>
