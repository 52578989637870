import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked", "id"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:checked', (event.target as HTMLInputElement).checked)),
      type: "checkbox",
      checked: $props.checked,
      id: $props.fieldId,
      class: "mr-2 hidden"
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: $props.fieldId,
      class: "flex flex-row items-center font-bold text-gray-800 cursor-pointer select-none w-full hover:bg-gray-50"
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["mr-2 rounded-sm border-2 border-gray-400", {
        'bg-gray-900': $props.checked,
        'bg-white': !$props.checked,
      }]),
        style: {"width":"10px","height":"10px"}
      }, null, 2),
      _createTextVNode(" " + _toDisplayString($props.label), 1)
    ], 8, _hoisted_2)
  ], 64))
}