import httpClient from './httpClient';

const getSession = (sessionId: string) =>
  httpClient.get(`/session/${sessionId}`);

const getSessions = (
  page: number,
  pageSize: number,
  sort: 'asc' | 'desc' = 'asc',
  recordLocator = '',
  startDate: Date,
  endDate: Date,
  airlines: string[],
  cancelController?: AbortController
) => {
  const startDateOffset = startDate.getTimezoneOffset();
  const endDateOffset = endDate.getTimezoneOffset();

  const offsetStartDate = new Date(
    startDate.getTime() - startDateOffset * 60 * 1000
  );
  const offsetEndDate = new Date(endDate.getTime() - endDateOffset * 60 * 1000);

  const formatedStartDate = offsetStartDate.toISOString().split('T')[0];
  const formatedEndDate = offsetEndDate.toISOString().split('T')[0];

  const params: {
    page: number;
    pageSize: number;
    sort: 'asc' | 'desc';
    recordLocator?: string;
    startDate: string;
    endDate: string;
    airlines: string | undefined;
  } = {
    page: page,
    pageSize,
    sort: sort,
    startDate: formatedStartDate,
    endDate: formatedEndDate,
    airlines: airlines.join('|') || undefined,
  };
  if (recordLocator != '') {
    params.recordLocator = recordLocator;
  }
  return httpClient.get(`/session`, {
    params: params,
    signal: cancelController?.signal || undefined,
  });
};

export { getSession, getSessions };
